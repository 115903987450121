import React, { Fragment } from "react";

import { Markdown, useMatchMedia, getProperty, MD } from "@btc/shared";

import { useMeta } from "hooks";

import { Twitter } from "./twitter";
import { Instagram } from "./instagram";
import { YouTube } from "./youtube";
import { Telegram } from "./telegram";
import { Linkedin } from "./linkedin";
import * as styles from "./social-media.module.scss";

const MEDIA_QUERIES = [`(min-width:${MD}px)`];

export const SocialMedia = () => {
  const meta = useMeta();
  const [isMD] = useMatchMedia(MEDIA_QUERIES);

  const content = getProperty(meta, "meta.social_media.content");
  const icons = (
    <Fragment>
      <Instagram />
      <Twitter />
      <YouTube />
      <Telegram />
      <Linkedin />
    </Fragment>
  );

  return isMD ? (
    <Fragment>
      <Markdown content={content} />
      <div className={styles.icons}>{icons}</div>
    </Fragment>
  ) : (
    icons
  );
};
