import React from "react";

import { Nav } from "./nav";
import { Copy } from "./copy";
import { Disclaimer } from "./disclaimer";

import * as styles from "./bottom.module.scss";

export const Bottom = () => {
  return (
    <div className={styles.wrapper}>
      <Nav />
      <div className={styles.copy}>
        <Copy />
        <Disclaimer />
      </div>
    </div>
  );
};
