import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { Container, MenuIcon } from "@btc/shared";

import { LogoLarge } from "./logo-large";
import { MenuOverlayNav } from "./menu-overlay-nav";

import * as styles from "./menu-overlay.module.scss";

export const MenuOverlay = ({ isOpen, onToggle }) => (
  <Fragment>
    <div className={styles.wrapper}>
      <Container>
        <div className={styles.container}>
          <div className={styles.logo}>
            <LogoLarge />
          </div>
          <button onClick={onToggle} className={styles.toggle}>
            <MenuIcon className={styles.toggleIcon} />
          </button>
        </div>
      </Container>
    </div>
    <MenuOverlayNav isOpen={isOpen} onClose={onToggle} />
  </Fragment>
);

MenuOverlay.defaultProps = {
  isOpen: false,
  onToggle: () => {},
};

MenuOverlay.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};
