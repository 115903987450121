exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-archive-jsx": () => import("./../../../src/templates/archive.jsx" /* webpackChunkName: "component---src-templates-archive-jsx" */),
  "component---src-templates-gtc-jsx": () => import("./../../../src/templates/gtc.jsx" /* webpackChunkName: "component---src-templates-gtc-jsx" */),
  "component---src-templates-home-jsx": () => import("./../../../src/templates/home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-imprint-jsx": () => import("./../../../src/templates/imprint.jsx" /* webpackChunkName: "component---src-templates-imprint-jsx" */),
  "component---src-templates-press-jsx": () => import("./../../../src/templates/press.jsx" /* webpackChunkName: "component---src-templates-press-jsx" */),
  "component---src-templates-privacy-jsx": () => import("./../../../src/templates/privacy.jsx" /* webpackChunkName: "component---src-templates-privacy-jsx" */),
  "component---src-templates-screen-jsx": () => import("./../../../src/templates/screen.jsx" /* webpackChunkName: "component---src-templates-screen-jsx" */),
  "component---src-templates-sponsoring-jsx": () => import("./../../../src/templates/sponsoring.jsx" /* webpackChunkName: "component---src-templates-sponsoring-jsx" */),
  "component---src-templates-tickets-jsx": () => import("./../../../src/templates/tickets.jsx" /* webpackChunkName: "component---src-templates-tickets-jsx" */)
}

