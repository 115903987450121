import React from "react";

import { useMatchMedia, MD } from "@btc/shared";

import { LogoLarge } from "../common/logo-large";
import { Contact } from "../common/contact";
import { Instagram } from "../common/instagram";
import { Twitter } from "../common/twitter";
import { YouTube } from "../common/youtube";
import { Telegram } from "../common/telegram";
import { Linkedin } from "../common/linkedin";
import { SocialMedia } from "../common/social-media";
import { EventSmall } from "../common/event-small";

import * as styles from "./top.module.scss";

const MEDIA_QUERIES = [`(min-width:${MD}px)`];

export const Top = () => {
  const [isMD] = useMatchMedia(MEDIA_QUERIES);

  return (
    <div className={styles.wrapper}>
      <div className={styles.item}>
        <div className={styles.holder}>
          {isMD ? (
            <div className={styles.logo}>
              <LogoLarge />
            </div>
          ) : (
            <Instagram className={styles.instagram} />
          )}
        </div>
      </div>

      <div className={styles.item}>
        <div className={styles.holder}>
          {isMD ? <EventSmall className={styles.event} /> : <Twitter className={styles.twitter} />}
        </div>
      </div>

      <div className={styles.item}>
        <div className={styles.holder}>
          {isMD ? <Contact className={styles.contact} /> : <YouTube className={styles.youtube} />}
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.holder}>
          {isMD ? (
            <SocialMedia className={styles.socialMedia} />
          ) : (
            <Telegram className={styles.telegram} />
          )}
        </div>
      </div>
      {!isMD ? (
        <div className={styles.item}>
          <div className={styles.holder}>
            <Linkedin className={styles.linkedin} />
          </div>
        </div>
      ): null}
    </div>
  );
};
