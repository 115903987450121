// extracted by mini-css-extract-plugin
export var black = "section-module--black--1eZts";
export var center = "section-module--center--jM3rw";
export var contentVisibility = "section-module--content-visibility--LTZrZ";
export var gradient = "section-module--gradient--VqATW";
export var isFullHeight = "section-module--is-full-height--NNjre";
export var left = "section-module--left--h1x5r";
export var lg = "section-module--lg--z7saf";
export var md = "section-module--md--Fws0P";
export var noPaddingBottom = "section-module--no-padding-bottom--2kpbA";
export var noPaddingTop = "section-module--no-padding-top---34ZB";
export var primary = "section-module--primary---mFBS";
export var right = "section-module--right--fkJAX";
export var secondary = "section-module--secondary--MGSOe";
export var sm = "section-module--sm--L6hjM";
export var transparent = "section-module--transparent--G9a3q";
export var white = "section-module--white--9QgSN";