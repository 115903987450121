import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { Container, CloseIcon, useMatchMedia, MD } from "@btc/shared";

import { Languages } from "../common/languages";

import { Info } from "./info";
import { LogoLarge } from "./logo-large";
import { PriceSmall } from "./price-small";
import { MenuOverlayNavItems } from "./menu-overlay-nav-items";

import * as styles from "./menu-overlay-nav.module.scss";

const MEDIA_QUERIES = [`(min-width:${MD}px)`];

export const MenuOverlayNav = ({ isOpen, onClose }) => {
  const wrapperClassName = useMemo(
    () => [styles.wrapper, isOpen ? styles.open : ""].filter(Boolean).join(" "),
    [isOpen]
  );

  const [isMD] = useMatchMedia(MEDIA_QUERIES);

  return (
    <nav className={wrapperClassName}>
      <Container>
        <div className={styles.container}>
          <div className={styles.navbar}>
            <div className={styles.logo}>
              <LogoLarge />
            </div>
            <button onClick={onClose} className={styles.close}>
              <CloseIcon className={styles.closeIcon} />
            </button>
          </div>
          <div className={styles.main}>
            <div className={styles.menu}>
              <MenuOverlayNavItems />
              <div className={styles.languages}>
                <Languages size="s4" />
              </div>
            </div>
            <div className={styles.info}>
              {!isMD && (
                <div className={styles.price}>
                  <PriceSmall />
                </div>
              )}
              <Info />
            </div>
          </div>
        </div>
      </Container>
    </nav>
  );
};

MenuOverlayNav.defaultProps = {
  isOpen: false,
  onClose: () => {},
};

MenuOverlayNav.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
