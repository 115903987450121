import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { TwitterIcon, getProperty } from "@btc/shared";

import { useMeta } from "hooks";

import * as styles from "./common.module.scss";

export const Twitter = ({ className }) => {
  const meta = useMeta();
  const wrapperClassName = useMemo(
    () => [styles.link, className].filter(Boolean).join(" "),
    [className]
  );

  const link = getProperty(meta, "meta.social_media.items.twitter");

  return (
    <a key={link} href={link} className={wrapperClassName} target="_blank" rel="noreferrer">
      <TwitterIcon className={styles.icon} />
    </a>
  );
};

Twitter.defaultProps = {
  className: null,
};

Twitter.propTypes = {
  className: PropTypes.string,
};
