"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.COLOR_TERTIARY = exports.COLOR_SECONDARY = exports.COLOR_PRIMARY = void 0;

var _exportsModule = require("../styles/_exports.module.scss");

const COLOR_PRIMARY = _exportsModule.color_primary;
exports.COLOR_PRIMARY = COLOR_PRIMARY;
const COLOR_SECONDARY = _exportsModule.color_secondary;
exports.COLOR_SECONDARY = COLOR_SECONDARY;
const COLOR_TERTIARY = _exportsModule.color_tertiary;
exports.COLOR_TERTIARY = COLOR_TERTIARY;