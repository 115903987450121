// extracted by mini-css-extract-plugin
export var black = "title-module--black--C1E5h";
export var gradient = "title-module--gradient--krc1k";
export var h1 = "title-module--h1--HvraU";
export var h2 = "title-module--h2--5iYLc";
export var h3 = "title-module--h3--tY5Kc";
export var h4 = "title-module--h4--L8AJq";
export var h5 = "title-module--h5--m+Tv+";
export var h6 = "title-module--h6--+iHU6";
export var inherit = "title-module--inherit--6hIow";
export var initial = "title-module--initial--ZUhTy";
export var lowercase = "title-module--lowercase--JEY0Y";
export var noMargin = "title-module--no-margin--FBt7m";
export var primary = "title-module--primary--+rN7Y";
export var s1 = "title-module--s1--tebHw";
export var s10 = "title-module--s10--X-vU0";
export var s2 = "title-module--s2--Bo5CF";
export var s3 = "title-module--s3--XlnPv";
export var s4 = "title-module--s4--AwYy0";
export var s5 = "title-module--s5--CMtAA";
export var s6 = "title-module--s6--0sjlG";
export var s7 = "title-module--s7--B8EMP";
export var s8 = "title-module--s8--Hz-9S";
export var script = "title-module--script--dSi28";
export var special = "title-module--special--5y121";
export var text = "title-module--text--K6NxA";
export var title = "title-module--title--ME039";
export var uppercase = "title-module--uppercase--19+3j";
export var white = "title-module--white--zVaD3";