import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { InstagramIcon, getProperty } from "@btc/shared";

import { useMeta } from "hooks";

import * as styles from "./common.module.scss";

export const Instagram = ({ className }) => {
  const meta = useMeta();
  const wrapperClassName = useMemo(
    () => [styles.link, className].filter(Boolean).join(" "),
    [className]
  );

  const link = getProperty(meta, "meta.social_media.items.instagram");

  return (
    <a key={link} href={link} className={wrapperClassName} target="_blank" rel="noreferrer">
      <InstagramIcon className={styles.icon} />
    </a>
  );
};

Instagram.defaultProps = {
  className: null,
};

Instagram.propTypes = {
  className: PropTypes.string,
};

