import React from "react";
import { Link } from "gatsby";

import { getProperty } from "@btc/shared";

import { LogoSmall as LogoSmallCommon } from "../common/logo-small";

import { useMeta } from "hooks";

import * as styles from "./logo-small.module.scss";

export const LogoSmall = () => {
  const meta = useMeta();

  const path = getProperty(meta, "meta.home.link.path");

  return (
    <Link className={styles.wrapper} to={path}>
      <LogoSmallCommon className={styles.icon} />
    </Link>
  );
};
