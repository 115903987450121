"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useMatchMedia = void 0;

var _react = require("react");

const useMatchMedia = expressions => {
  const [matches, setMatches] = (0, _react.useState)(() => Array.from({
    length: expressions.length
  }).map(() => false));
  (0, _react.useEffect)(() => {
    const teardowns = expressions.map((query, index) => {
      const handler = _ref => {
        let {
          matches
        } = _ref;
        return setMatches(oldMatches => {
          const newMatches = [...oldMatches];
          newMatches[index] = matches;
          return newMatches;
        });
      };

      const mql = window.matchMedia(query);
      mql.addEventListener('change', handler);
      handler(mql);
      return () => mql.removeEventListener('change', handler);
    });
    return () => teardowns.forEach(teardown => teardown());
  }, [expressions]);
  return matches;
};

exports.useMatchMedia = useMatchMedia;