import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { MenuIcon } from "@btc/shared";

import { LogoSmall } from "./logo-small";
import { MenuSidebarNav } from "./menu-sidebar-nav";

import * as styles from "./menu-sidebar.module.scss";

export const MenuSidebar = ({ isOpen, onToggle }) => {
  const wrapperClassName = useMemo(
    () => [isOpen ? styles.open : ""].join(" "),
    [isOpen]
  );

  return (
    <div className={wrapperClassName}>
      <div className={styles.sidebar}>
        <div className={styles.logo}>
          <LogoSmall />
        </div>
        <button onClick={onToggle} className={styles.toggle}>
          <MenuIcon className={styles.toggleIcon} />
        </button>
        <div className={styles.nav}>
          <MenuSidebarNav isOpen={isOpen} />
        </div>
      </div>
      <button className={styles.background} onClick={onToggle} />
    </div>
  );
};

MenuSidebar.defaultProps = {
  isOpen: false,
  onToggle: () => {},
};

MenuSidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};
