import React, { useMemo } from "react";

import { Text, getProperty, useFormatterDateFromTo } from "@btc/shared";
import PropTypes from "prop-types";

import { useEvent } from "hooks";

import * as styles from "./event-small.module.scss";

export const EventSmall = ({ mode, className }) => {
  const event = useEvent();
  const wrapperClassName = useMemo(() => [className].filter(Boolean).join(" "), [className]);

  const date = useFormatterDateFromTo(
    getProperty(event, "event.date.from"),
    getProperty(event, "event.date.to")
  );
  const location = getProperty(event, "event.location.name");

  return (
    <div className={wrapperClassName}>
      <Text size="s2" transform="uppercase" className={styles.date}>
        {date}
      </Text>
      <Text size="s2" transform="uppercase">
        {location}
      </Text>
    </div>
  );
};

EventSmall.defaultProps = {
  className: null,
};

EventSmall.propTypes = {
  className: PropTypes.string,
};
