import React from "react";

import { Text, getProperty } from "@btc/shared";

import { useFooter, useMeta } from "hooks";

export const Copy = () => {
  const meta = useMeta();
  const footer = useFooter();

  const company = getProperty(meta, "meta.company");
  const copy = getProperty(footer, "copy");

  return (
    <Text size="s1" color="white" wrapper="span">
      &copy; {company} {copy}
    </Text>
  );
};
