import React from "react";

import { Text, getProperty } from "@btc/shared";

import { useFooter } from "hooks";

import * as styles from "./disclaimer.module.scss";

export const Disclaimer = () => {
  const footer = useFooter();

  const text = getProperty(footer, "disclaimer.text");
  const url = getProperty(footer, "disclaimer.url");

  return (
    <a href={url} target="_blank" rel="noreferrer" className={styles.wrapper}>
      <Text size="s1">{text}</Text>
    </a>
  );
};
