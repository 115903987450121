import { useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";

import { useLocale, getProperty } from "@btc/shared";

export const useMeta = () => {
  const refData = useRef(null);
  const refLocale = useRef(null);
  const { locale: currentLocale } = useLocale();
  const key = "allMarkdownRemark.edges";
  const results = useStaticQuery(query) || {};
  const edges = getProperty(results, key) || [];

  if (refData.current === null || refLocale.current !== currentLocale) {
    const [edge] = edges
      .map((edge) => getProperty(edge, "node.frontmatter"))
      .filter(({ locale }) => currentLocale === locale);

    refData.current = edge;
  }

  refLocale.current = currentLocale;

  return refData.current;
};

const query = graphql`
  query useMetaQuery {
    allMarkdownRemark(filter: { frontmatter: { type: { eq: "meta" } } }) {
      edges {
        node {
          frontmatter {
            locale

            meta {
              company

              contact {
                  content
                  email
              }

              social_media {
                  content
                  items {
                      instagram
                      twitter
                      youtube
                      telegram
                      linkedin
                  }
              }

              home {
                  link {
                      path
                      text
                  }
              }

              tickets {
                  link {
                      path
                      text
                  }
              }

              schedule {
                  labels {
                      categories {
                          chat
                          keynote
                          panel
                          workshop
                          default
                      }
                      calendar {
                          linkText
                      }
                  }
              }

              form {
                  status {
                      sending
                  }
                  error {
                      required
                  }
              }

              error {
                  title
                  content
              }
              back_home
              more_link

              cookies {
                  video_text
                  video_button
              }
            }
          }
        }
      }
    }
  }
`;
