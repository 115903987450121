import React, { useMemo } from "react";

import { Text, getProperty, useFormatterDateFromTo } from "@btc/shared";
import PropTypes from "prop-types";

import { useEvent } from "hooks";

import * as styles from "./event-large.module.scss";

export const EventLarge = ({ className }) => {
  const event = useEvent();
  const wrapperClassName = useMemo(() => [className].filter(Boolean).join(" "), [className]);

  const date = useFormatterDateFromTo(
    getProperty(event, "event.date.from"),
    getProperty(event, "event.date.to")
  );
  const location = getProperty(event, "event.location.name");

  return (
    <div className={wrapperClassName}>
      <div className={styles.date}>
        <Text size="s3" type="special" transform="uppercase">
          {date}
        </Text>
      </div>
      <div>
        <Text size="s3" type="special" transform="uppercase">
          {location}
        </Text>
      </div>
    </div>
  );
};

EventLarge.defaultProps = {
  className: null,
};

EventLarge.propTypes = {
  className: PropTypes.string,
};
