import React, { Fragment } from "react";

import { Link, navigate } from "gatsby";

import { useLocale, Text } from "@btc/shared";

import * as styles from "./languages.module.scss";

export const Languages = ({ size }) => {
  const { locales, defaultLocale, locale: currentLocale, setLocale } = useLocale();

  return (
    <Fragment>
      {locales.map((locale, index) => {
        const clickHandler = (event) => {
          event.preventDefault();
          setLocale(locale);
          navigate(path);
        };

        const showSeparator = index < locales.length - 1;
        const path = locale === defaultLocale ? `/` : `/${locale}`;
        const className = `${styles.link} ${locale === currentLocale ? styles.active : ""}`;

        return (
          <Link key={locale} to={path} className={className} onClick={clickHandler}>
            <Text
              size={size}
              color="inherit"
              wrapper="span"
              weight="light"
              transform="uppercase"
              className={styles.text}>
              {locale}
            </Text>
            {showSeparator && (
              <Text size={size} color="inherit" wrapper="span" weight="light" transform="uppercase">
                {" / "}
              </Text>
            )}
          </Link>
        );
      })}
    </Fragment>
  );
};

Languages.defaultProps = {
  size: "s2",
};

Languages.propTypes = {
  size: Text.propTypes.size,
};
