"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useInView = void 0;

var _react = require("react");

const useInView = function (ref) {
  let {
    once
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
    once: false
  };
  const [isIntersecting, setIsIntersecting] = (0, _react.useState)(false);
  const observerRef = (0, _react.useRef)(null);

  const unobserve = () => {
    if (observerRef.current) {
      observerRef.current.disconnect();
    }
  };

  (0, _react.useEffect)(() => {
    if (typeof window.IntersectionObserver === "undefined") {
      setIsIntersecting(true);
      return;
    }

    observerRef.current = new IntersectionObserver(_ref => {
      let [{
        isIntersecting
      }] = _ref;
      setIsIntersecting(isIntersecting);

      if (isIntersecting && once) {
        unobserve();
      }
    }, {
      root: null,
      treshold: 0.5
    });

    if (ref.current) {
      observerRef.current.observe(ref.current);
    }

    return () => unobserve();
  }, []);
  return isIntersecting;
};

exports.useInView = useInView;