import React from "react";

import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_TERTIARY } from "@btc/shared";

import * as styles from "./svg-defs.module.scss";

export const SvgDefs = () => (
  <svg className={styles.svg}>
    <defs>
      <linearGradient id="gradient">
        <stop offset="25%" stopColor={COLOR_SECONDARY} />
        <stop offset="60%" stopColor={COLOR_PRIMARY} />
        <stop offset="100%" stopColor={COLOR_TERTIARY} />
      </linearGradient>
    </defs>
  </svg>
);
