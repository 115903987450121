import React from "react";
import PropTypes from "prop-types";

import { MenuOverlay } from "./header/menu-overlay";
import { MenuSidebar } from "./header/menu-sidebar";

import * as styles from "./header.module.scss";

export const Header = ({ showMenu, onToggle }) => (
  <header>
    <div className={styles.sidebar}>
      <MenuSidebar isOpen={showMenu} onToggle={onToggle} />
    </div>
    <div className={styles.overlay}>
      <MenuOverlay isOpen={showMenu} onToggle={onToggle} />
    </div>
  </header>
);

Header.defaultProps = {
  showMenu: false,
  onToggle: () => {},
};

Header.propTypes = {
  showMenu: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};
