"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "COLOR_PRIMARY", {
  enumerable: true,
  get: function () {
    return _colors.COLOR_PRIMARY;
  }
});
Object.defineProperty(exports, "COLOR_SECONDARY", {
  enumerable: true,
  get: function () {
    return _colors.COLOR_SECONDARY;
  }
});
Object.defineProperty(exports, "COLOR_TERTIARY", {
  enumerable: true,
  get: function () {
    return _colors.COLOR_TERTIARY;
  }
});
Object.defineProperty(exports, "LG", {
  enumerable: true,
  get: function () {
    return _breakpoints.LG;
  }
});
Object.defineProperty(exports, "MD", {
  enumerable: true,
  get: function () {
    return _breakpoints.MD;
  }
});
Object.defineProperty(exports, "SM", {
  enumerable: true,
  get: function () {
    return _breakpoints.SM;
  }
});
Object.defineProperty(exports, "XL", {
  enumerable: true,
  get: function () {
    return _breakpoints.XL;
  }
});
Object.defineProperty(exports, "XS", {
  enumerable: true,
  get: function () {
    return _breakpoints.XS;
  }
});
Object.defineProperty(exports, "XXL", {
  enumerable: true,
  get: function () {
    return _breakpoints.XXL;
  }
});
Object.defineProperty(exports, "arrayToObject", {
  enumerable: true,
  get: function () {
    return _array.arrayToObject;
  }
});
Object.defineProperty(exports, "debounce", {
  enumerable: true,
  get: function () {
    return _debounce.debounce;
  }
});
Object.defineProperty(exports, "detectLanguage", {
  enumerable: true,
  get: function () {
    return _i18n.detectLanguage;
  }
});
Object.defineProperty(exports, "extractLanguage", {
  enumerable: true,
  get: function () {
    return _i18n.extractLanguage;
  }
});
Object.defineProperty(exports, "formatDate", {
  enumerable: true,
  get: function () {
    return _date.formatDate;
  }
});
Object.defineProperty(exports, "formatDateFromTo", {
  enumerable: true,
  get: function () {
    return _date.formatDateFromTo;
  }
});
Object.defineProperty(exports, "formatTime", {
  enumerable: true,
  get: function () {
    return _date.formatTime;
  }
});
Object.defineProperty(exports, "formatTimeFromTo", {
  enumerable: true,
  get: function () {
    return _date.formatTimeFromTo;
  }
});
Object.defineProperty(exports, "getProperty", {
  enumerable: true,
  get: function () {
    return _object.getProperty;
  }
});
Object.defineProperty(exports, "getScrollOffsetY", {
  enumerable: true,
  get: function () {
    return _dom.getScrollOffsetY;
  }
});
Object.defineProperty(exports, "getStyleProperty", {
  enumerable: true,
  get: function () {
    return _dom.getStyleProperty;
  }
});
Object.defineProperty(exports, "isArray", {
  enumerable: true,
  get: function () {
    return _array.isArray;
  }
});
Object.defineProperty(exports, "isClient", {
  enumerable: true,
  get: function () {
    return _misc.isClient;
  }
});
Object.defineProperty(exports, "isEmailValid", {
  enumerable: true,
  get: function () {
    return _email.isValid;
  }
});
Object.defineProperty(exports, "isObject", {
  enumerable: true,
  get: function () {
    return _object.isObject;
  }
});
Object.defineProperty(exports, "isPhoneValid", {
  enumerable: true,
  get: function () {
    return _phone.isValid;
  }
});
Object.defineProperty(exports, "isSameDate", {
  enumerable: true,
  get: function () {
    return _date.isSameDate;
  }
});
Object.defineProperty(exports, "isSameTime", {
  enumerable: true,
  get: function () {
    return _date.isSameTime;
  }
});
Object.defineProperty(exports, "isURL", {
  enumerable: true,
  get: function () {
    return _url.isURL;
  }
});
Object.defineProperty(exports, "isValidDate", {
  enumerable: true,
  get: function () {
    return _date.isValidDate;
  }
});
Object.defineProperty(exports, "joinPaths", {
  enumerable: true,
  get: function () {
    return _url.joinPaths;
  }
});
Object.defineProperty(exports, "normalizePath", {
  enumerable: true,
  get: function () {
    return _url.normalizePath;
  }
});
Object.defineProperty(exports, "observeResize", {
  enumerable: true,
  get: function () {
    return _dom.observeResize;
  }
});
Object.defineProperty(exports, "removeProperty", {
  enumerable: true,
  get: function () {
    return _object.removeProperty;
  }
});
Object.defineProperty(exports, "resolvePath", {
  enumerable: true,
  get: function () {
    return _url.resolvePath;
  }
});
Object.defineProperty(exports, "scrollTo", {
  enumerable: true,
  get: function () {
    return _dom.scrollTo;
  }
});
Object.defineProperty(exports, "setProperty", {
  enumerable: true,
  get: function () {
    return _object.setProperty;
  }
});
Object.defineProperty(exports, "toEmailUri", {
  enumerable: true,
  get: function () {
    return _email.toUri;
  }
});
Object.defineProperty(exports, "toPhoneUri", {
  enumerable: true,
  get: function () {
    return _phone.toUri;
  }
});
Object.defineProperty(exports, "trimSlashes", {
  enumerable: true,
  get: function () {
    return _url.trimSlashes;
  }
});

var _array = require("./array");

var _breakpoints = require("./breakpoints");

var _date = require("./date");

var _debounce = require("./debounce");

var _dom = require("./dom");

var _email = require("./email");

var _i18n = require("./i18n");

var _misc = require("./misc");

var _object = require("./object");

var _phone = require("./phone");

var _url = require("./url");

var _colors = require("./colors");