"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useCounter = void 0;

var _react = require("react");

var _utils = require("../utils");

// Easing Functions
// https://github.com/danro/jquery-easing/blob/master/jquery.easing.js
// t: current time, b: beginning value, c: change in value, d: duration
const easeOutCirc = (t, b, c, d) => c * Math.sqrt(1 - (t = t / d - 1) * t) + b;

const useCounter = function () {
  let from = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  let to = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 100;
  let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  const [count, setCount] = (0, _react.useState)(from);
  const duration = (0, _utils.getProperty)(options, "duration") || 1000;
  const fractions = (0, _utils.getProperty)(options, "fractions") || 0;
  const ease = (0, _utils.getProperty)(options, "ease") || easeOutCirc;
  (0, _react.useEffect)(() => {
    const parsed = {
      from: parseFloat(from),
      to: parseFloat(to)
    };

    if (isNaN(parsed.from) || isNaN(parsed.to)) {
      setCount(to);
      return;
    }

    let startTimestamp, requestId;

    const step = timestamp => {
      if (!startTimestamp) {
        startTimestamp = timestamp;
      }

      const elapsed = timestamp - startTimestamp;
      const progress = Math.min(elapsed / duration, 1);
      const current = typeof ease === "function" ? ease(elapsed, parsed.from, parsed.to - parsed.from, duration) : progress * (parsed.to - parsed.from) + parsed.from;
      setCount(+current.toFixed(fractions));

      if (progress < 1) {
        requestId = window.requestAnimationFrame(step);
      }
    };

    requestId = window.requestAnimationFrame(step);
    return () => window.cancelAnimationFrame(requestId);
  }, []);
  return count;
};

exports.useCounter = useCounter;