import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { Markdown, MailIcon, toEmailUri, useMatchMedia, getProperty, MD } from "@btc/shared";

import { useMeta } from "hooks";

import * as styles from "./common.module.scss";

const MEDIA_QUERIES = [`(min-width:${MD}px)`];

export const Contact = ({ className }) => {
  const meta = useMeta();
  const [isMD] = useMatchMedia(MEDIA_QUERIES);
  const wrapperClassName = useMemo(
    () => [isMD ? "" : styles.link, className].filter(Boolean).join(" "),
    [isMD, className]
  );

  const email = getProperty(meta, "meta.contact.email");
  const content = getProperty(meta, "meta.contact.content");

  return isMD ? (
    <div className={wrapperClassName}>
      <Markdown content={content} className={styles.text}/>
      <a href={toEmailUri(email)} className={styles.link}>
        {email}
      </a>
    </div>
  ) : (
    <a href={toEmailUri(email)} className={wrapperClassName}>
      <MailIcon className={styles.icon} />
    </a>
  );
};

Contact.defaultProps = {
  className: null,
};

Contact.propTypes = {
  className: PropTypes.string,
};
