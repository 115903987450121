"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatDate = formatDate;
exports.formatDateFromTo = formatDateFromTo;
exports.formatTime = formatTime;
exports.formatTimeFromTo = formatTimeFromTo;
exports.isSameDate = isSameDate;
exports.isSameTime = isSameTime;
exports.isValidDate = isValidDate;

function isValidDate(date) {
  return date instanceof Date && !isNaN(date);
}

function isSameDate(a, b) {
  return a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate();
}

function isSameTime(a, b) {
  return a.getHours() === b.getHours() && a.getMinutes() === b.getMinutes() && a.getSeconds() === b.getSeconds();
}

function formatDate(date) {
  let locale = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;
  let format = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "short";
  let timeZone = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : undefined;
  date = new Date(date);

  if (!isValidDate(date)) {
    return "";
  }

  switch (format) {
    case "short":
      return date.toLocaleDateString(locale, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        weekday: "short",
        timeZone
      });

    case "long":
      return date.toLocaleDateString(locale, {
        year: "numeric",
        month: "long",
        day: "2-digit",
        weekday: "long",
        timeZone
      });
  }
}

function formatDateFromTo(from, to) {
  let locale = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : undefined;
  let format = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "short";
  let timeZone = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : undefined;
  from = new Date(from);
  to = new Date(to);

  if (!isValidDate(from) || !isValidDate(to)) {
    return "";
  }

  switch (format) {
    case "short":
      return isSameDate(from, to) ? "".concat(from.toLocaleDateString(locale, {
        month: "long",
        timeZone
      }), " ").concat(from.toLocaleDateString(undefined, {
        day: "numeric",
        timeZone
      }), " ").concat(from.toLocaleDateString(undefined, {
        year: "numeric",
        timeZone
      })) : "".concat(from.toLocaleDateString(locale, {
        month: "long",
        timeZone
      }), " ").concat(from.toLocaleDateString(undefined, {
        day: "numeric",
        timeZone
      }), "-").concat(to.toLocaleDateString(undefined, {
        day: "numeric",
        timeZone
      }), " ").concat(from.toLocaleDateString(undefined, {
        year: "numeric",
        timeZone
      }));

    case "long":
      return isSameDate(from, to) ? "".concat(from.toLocaleDateString(locale, {
        weekday: "short",
        timeZone
      }), ", ").concat(from.toLocaleDateString(locale, {
        month: "long",
        timeZone
      }), " ").concat(from.toLocaleDateString(undefined, {
        day: "numeric",
        timeZone
      }), " ").concat(from.toLocaleDateString(undefined, {
        year: "numeric",
        timeZone
      })) : "".concat(from.toLocaleDateString(locale, {
        weekday: "short",
        timeZone
      }), "-").concat(to.toLocaleDateString(locale, {
        weekday: "short",
        timeZone
      }), ", ").concat(from.toLocaleDateString(locale, {
        month: "long",
        timeZone
      }), " ").concat(from.toLocaleDateString(undefined, {
        day: "numeric",
        timeZone
      }), "-").concat(to.toLocaleDateString(undefined, {
        day: "numeric",
        timeZone
      }), " ").concat(from.toLocaleDateString(undefined, {
        year: "numeric",
        timeZone
      }));
  }
}

function formatTime(date) {
  let locale = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;
  let timeZone = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : undefined;
  date = new Date(date);

  if (!isValidDate(date)) {
    return "";
  }

  return "".concat(date.toLocaleTimeString(locale, {
    hour: "2-digit",
    minute: "2-digit",
    timeZone
  }));
}

function formatTimeFromTo(from, to) {
  let locale = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : undefined;
  let timeZone = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : undefined;
  from = new Date(from);
  to = new Date(to);

  if (!isValidDate(from) || !isValidDate(to)) {
    return "";
  }

  return isSameTime(from, to) ? from.toLocaleTimeString(locale, {
    hour: "2-digit",
    minute: "2-digit",
    timeZone
  }) : "".concat(from.toLocaleTimeString(locale, {
    hour: "2-digit",
    minute: "2-digit",
    timeZone
  }), " - ").concat(to.toLocaleTimeString(locale, {
    hour: "2-digit",
    minute: "2-digit",
    timeZone
  }));
}