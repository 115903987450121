// extracted by mini-css-extract-plugin
export var contact = "top-module--contact--Wz1GV";
export var event = "top-module--event--XInpm";
export var holder = "top-module--holder--TNn9f";
export var instagram = "top-module--instagram--Y35hA";
export var item = "top-module--item--DxWs-";
export var linkedin = "top-module--linkedin--MK59k";
export var logo = "top-module--logo--S+8W1";
export var mail = "top-module--mail--weTs0";
export var socialMedia = "top-module--social-media--ePZ21";
export var telegram = "top-module--telegram--S14pm";
export var twitter = "top-module--twitter--hIR-1";
export var wrapper = "top-module--wrapper--T5xYs";
export var youtube = "top-module--youtube--8k8L0";