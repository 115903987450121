import customWrapPageElement from "./wrap-page-element";

import { getProperty } from "@btc/shared";

import "./src/styles/main.scss";

export const wrapPageElement = customWrapPageElement;

export const onRouteUpdate = ({ location, prevLocation }) => {
  const state = getProperty(location, "state");
  if (state) {
    location.state.referrer = prevLocation ? prevLocation.pathname : null;
  }
};

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  const anchor = document.location.hash.substring(1);
  const element = document.getElementById(anchor);

  if (element) {
    element.scrollIntoView();
  } else {
    const skipScroll = getProperty(location, "state.skipScroll");
    const currentPosition = getSavedScrollPosition(location);
    if (!skipScroll) {
      window.scrollTo(...(currentPosition || [0, 0]));
    }
  }

  // return false;
};
