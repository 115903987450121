// extracted by mini-css-extract-plugin
export var close = "menu-overlay-nav-module--close--3gIAD";
export var closeIcon = "menu-overlay-nav-module--close-icon--T0Q0j";
export var container = "menu-overlay-nav-module--container--vyJeA";
export var info = "menu-overlay-nav-module--info--DrFjv";
export var languages = "menu-overlay-nav-module--languages--v-04A";
export var logo = "menu-overlay-nav-module--logo--kDjcw";
export var main = "menu-overlay-nav-module--main--kKwqz";
export var menu = "menu-overlay-nav-module--menu--UUdD9";
export var navbar = "menu-overlay-nav-module--navbar--uPIKp";
export var open = "menu-overlay-nav-module--open--mJZA0";
export var price = "menu-overlay-nav-module--price---xqfd";
export var wrapper = "menu-overlay-nav-module--wrapper--jSWHK";