import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { LogoSmallIcon } from "@btc/shared";

import * as styles from "./logo-large.module.scss";

export const LogoSmall = ({ className }) => {
  const wrapperClassName = useMemo(
    () => [styles.wrapper, className].filter(Boolean).join(" "),
    [className]
  );

  return <LogoSmallIcon className={wrapperClassName} />;
};

LogoSmall.defaultProps = {
  className: null,
};

LogoSmall.propTypes = {
  className: PropTypes.string,
};
