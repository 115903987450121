import React from "react";

import { Languages } from "../common/languages";
import { PriceLarge } from "../common/price-large";
import { TicketLarge } from "../common/ticket-large";

import { LogoLarge } from "./logo-large";
import { MenuSidebarNavItems } from "./menu-sidebar-nav-items";

import * as styles from "./menu-sidebar-nav.module.scss";

export const MenuSidebarNav = ({ isOpen, onClose }) => (
  <nav className={styles.wrapper}>
    <div className={styles.logo}>
      <LogoLarge />
    </div>
    <div className={styles.items}>
      <MenuSidebarNavItems />
    </div>
    <div className={styles.languages}>
      <Languages />
    </div>
    <div className={styles.price}>
      <PriceLarge />
    </div>
    <div className={styles.ticket}>
      <TicketLarge />
    </div>
  </nav>
);
