import React from "react";
import { Link } from "gatsby";

import { Text, TicketSmallIcon, getProperty } from "@btc/shared";

import { useMeta, useHeader } from "hooks";

import * as styles from "./ticket-large.module.scss";

export const TicketLarge = ({ theme }) => {
  const meta = useMeta();
  const { ticket } = useHeader();

  const path = getProperty(meta, "meta.tickets.link.path");

  return (
    <Link to={path} className={styles.wrapper}>
      <TicketSmallIcon className={styles.icon} />
      <Text size="s1" color="primary">
        {ticket}
      </Text>
    </Link>
  );
};
