// extracted by mini-css-extract-plugin
export var active = "button-module--active--zU32q";
export var arrow = "button-module--arrow--aQmOD";
export var black = "button-module--black--MifFa";
export var border = "button-module--border--E98z2";
export var icon = "button-module--icon--92eSN";
export var initial = "button-module--initial--lwKiT";
export var invert = "button-module--invert--ZtRPN";
export var lg = "button-module--lg--rw4DA";
export var md = "button-module--md--nNrHg";
export var minimal = "button-module--minimal--RJ7Vr";
export var primary = "button-module--primary--1HyVJ";
export var sm = "button-module--sm--VJR+t";
export var text = "button-module--text--VSSqd";
export var white = "button-module--white--TjjTm";
export var wrapper = "button-module--wrapper--9r4xk";