import React from "react";

import { Text, PriceIcon } from "@btc/shared";

import { usePrice } from "hooks";

import * as styles from "./price-large.module.scss";

export const PriceLarge = () => {
  const { formatted } = usePrice();

  return (
    <div className={styles.wrapper}>
      <PriceIcon className={styles.icon} />
      <Text size="s1" color="primary">{`1 BTC = ${formatted} EUR`}</Text>
    </div>
  );
};
