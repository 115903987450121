import { useEffect, useState, useRef } from "react";

import { useLocale, getProperty } from "@btc/shared";

const API_URL = `https://api.coindesk.com/v1/bpi/currentprice.json`;
const BTC_KEY = `bpi.EUR.rate_float`;

const getPrice = async () => {
  try {
    const response = await fetch(API_URL, {
      mode: "cors",
      method: "GET",
    });

    const json = await response.json();
    return getProperty(json, BTC_KEY);
  } catch (e) {
    return null;
  }
};

export const usePrice = () => {
  const [price, setPrice] = useState(0);
  const isMountedRef = useRef(true);
  const { locale } = useLocale();

  useEffect(() => {
    const fetchAndUpdatePrice = async () => {
      const price = await getPrice();
      if (!isMountedRef.current) {
        return;
      }

      setPrice(price);
    };

    fetchAndUpdatePrice();

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return typeof price !== "number"
    ? {
        price: null,
        formatted: 'n/a',
      }
    : {
        price,
        formatted: price.toLocaleString(locale, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
      };
};
