import React from "react";

import { Text } from "@btc/shared";

import { usePrice } from "hooks";

export const PriceSmall = () => {
  const { formatted } = usePrice();

  return <Text size="s4" color="primary">{`1 BTC = ${formatted} EUR`}</Text>;
};
