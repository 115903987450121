"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.XXL = exports.XS = exports.XL = exports.SM = exports.MD = exports.LG = void 0;

var _exportsModule = require("../styles/_exports.module.scss");

// parse SASS variables and convert rem to px: "1rem" -> 16
const XS = 16 * parseFloat(_exportsModule.breakpoint_xs);
exports.XS = XS;
const SM = 16 * parseFloat(_exportsModule.breakpoint_sm);
exports.SM = SM;
const MD = 16 * parseFloat(_exportsModule.breakpoint_md);
exports.MD = MD;
const LG = 16 * parseFloat(_exportsModule.breakpoint_lg);
exports.LG = LG;
const XL = 16 * parseFloat(_exportsModule.breakpoint_xl);
exports.XL = XL;
const XXL = 16 * parseFloat(_exportsModule.breakpoint_xxl);
exports.XXL = XXL;