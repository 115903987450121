// extracted by mini-css-extract-plugin
export var black = "text-module--black--OAOKX";
export var bold = "text-module--bold--MSMm-";
export var gradient = "text-module--gradient--HfDKC";
export var grey = "text-module--grey--FOqSt";
export var initial = "text-module--initial--ZwZFL";
export var light = "text-module--light--CiJ3-";
export var lowercase = "text-module--lowercase--DIto5";
export var medium = "text-module--medium--thIYB";
export var normal = "text-module--normal--yNDhk";
export var primary = "text-module--primary--IiM5P";
export var s1 = "text-module--s1--8fk6R";
export var s10 = "text-module--s10---8g62";
export var s2 = "text-module--s2--KFyca";
export var s3 = "text-module--s3--4wHES";
export var s4 = "text-module--s4--urt-Q";
export var s5 = "text-module--s5--uRbHc";
export var s6 = "text-module--s6--LVGwD";
export var s7 = "text-module--s7--YCcrZ";
export var s8 = "text-module--s8--Smonq";
export var script = "text-module--script--A2-gk";
export var secondary = "text-module--secondary--vkU0S";
export var special = "text-module--special--VIepj";
export var uppercase = "text-module--uppercase--dwtTD";
export var white = "text-module--white--6znW1";