import React, { Fragment } from "react";

import { useMatchMedia, MD } from "@btc/shared";

import { Contact } from "../common/contact";
import { EventLarge } from "../common/event-large";
import { SocialMedia } from "../common/social-media";

import * as styles from "./info.module.scss";

const MEDIA_QUERIES = [`(min-width:${MD}px)`];

export const Info = () => {
  const [isMD] = useMatchMedia(MEDIA_QUERIES);

  return (
    <Fragment>
      {isMD && (
        <div className={styles.event}>
          <EventLarge />
        </div>
      )}
      <div className={styles.contact}>
        <Contact />
      </div>
      <div className={styles.socialmedia}>
        <SocialMedia />
      </div>
    </Fragment>
  );
};
