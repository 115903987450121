"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useFormatterTimeFromTo = exports.useFormatterTime = exports.useFormatterDateFromTo = exports.useFormatterDate = void 0;

var _react = require("react");

var _shared = require("@btc/shared");

const useFormatterDate = function (date) {
  let format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "short";
  const {
    locale
  } = (0, _shared.useLocale)();
  const [formatted, setFormatted] = (0, _react.useState)(() => (0, _shared.formatDate)(date, locale, format, "UTC"));
  (0, _react.useEffect)(() => setFormatted((0, _shared.formatDate)(date, locale, format)), [date, locale, format]);
  return formatted;
};

exports.useFormatterDate = useFormatterDate;

const useFormatterTime = date => {
  const {
    locale
  } = (0, _shared.useLocale)();
  const [formatted, setFormatted] = (0, _react.useState)(() => (0, _shared.formatTime)(date, locale, "UTC"));
  (0, _react.useEffect)(() => setFormatted((0, _shared.formatTime)(date, locale)), [date, locale]);
  return formatted;
};

exports.useFormatterTime = useFormatterTime;

const useFormatterTimeFromTo = (from, to) => {
  const {
    locale
  } = (0, _shared.useLocale)();
  const [formatted, setFormatted] = (0, _react.useState)(() => (0, _shared.formatTimeFromTo)(from, to, locale, "UTC"));
  (0, _react.useEffect)(() => setFormatted((0, _shared.formatTimeFromTo)(from, to, locale)), [from, to, locale]);
  return formatted;
};

exports.useFormatterTimeFromTo = useFormatterTimeFromTo;

const useFormatterDateFromTo = function (from, to) {
  let format = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "short";
  const {
    locale
  } = (0, _shared.useLocale)();
  const [formatted, setFormatted] = (0, _react.useState)(() => (0, _shared.formatDateFromTo)(from, to, locale, "UTC"));
  (0, _react.useEffect)(() => setFormatted((0, _shared.formatDateFromTo)(from, to, locale, format)), [from, to, locale]);
  return formatted;
};

exports.useFormatterDateFromTo = useFormatterDateFromTo;