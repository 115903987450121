import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { LogoLargeIcon } from "@btc/shared";

import * as styles from "./logo-large.module.scss";

export const LogoLarge = ({ color, className }) => {
  const wrapperClassName = useMemo(
    () => [styles.wrapper, styles[color], className].filter(Boolean).join(" "),
    [color, className]
  );

  return <LogoLargeIcon className={wrapperClassName} />;
};

LogoLarge.defaultProps = {
  color: "black",
  className: null,
};

LogoLarge.propTypes = {
  color: PropTypes.oneOf(["initial", "black", "white", "invert", "mixed"]).isRequired,
  className: PropTypes.string,
};
