import React, { Fragment } from "react";

import { LocaleProvider, ThemeProvider, ScrollProvider, getProperty } from "@btc/shared";

import { Layout } from "theme";

const layoutFactory = ({ element, props }) => {
  const template = getProperty(props, "pageContext.template");

  switch (template) {
    case "screen":
      return <Fragment>{element}</Fragment>;
    default:
      return <Layout {...props}>{element}</Layout>;
  }
};

const wrapPageElement = ({ element, props }) => {
  const locale = getProperty(props, "pageContext.locale");
  const layout = layoutFactory({ element, props });

  return (
    <LocaleProvider locale={locale}>
      <ScrollProvider>
        <ThemeProvider>{layout}</ThemeProvider>
      </ScrollProvider>
    </LocaleProvider>
  );
};

export default wrapPageElement;
